import React from "react";
import { Layout } from "../components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import ProductBanner from "../components/product_banners/product_banners";
import Banner from "../imgs/bg_legales.jpg";
import DownloadIcon from "../imgs/download-icon.svg";
import "./comisiones.css";

const ComisionesPage = () => {
    const pageTitle = 'Aranceles y Comisiones | Balanz';
    const pageDesc = 'Somos Balanz, especialistas en inversiones con una trayectoria de 20 años en el mercado de capitales local. Contamos con más 200.000 clientes.';



const page = (
    <>
        <ProductBanner
            bannerContainerClassName="bannerLegales"
            bannerTrasparecy="bannerMask"
            productTitle="Aranceles y comisiones"
            bannerImage={Banner}
            bannerImageClassName="cedearsBannerImage"
            textContainerClassName="textContainer"
            bannerText={false}
            bannerTextClassName="bannerTextInversiones"
            bannerImageMobile={Banner}
            mobile={false}
        />
        <section className="fullwidth legales">
            <Container>
                <Row className="g-0 d-flex justify-content-start" >
                    <Col xs={12} className="pb-0 pt-sm-5">
                        <p className='base-text mb-5'>Se hace saber que, en función a los volúmenes y frecuencia de operaciones podrán aplicarse bonificaciones. La presente tabla de aranceles y comisiones fue aprobada por Acta de Directorio N° 231 de fecha 17 de enero de 2025.</p>
                        <div className="table-responsive mt-3">
                            <table className="w-100 comisiones-table table">
                                <thead>
                                    <th>&nbsp;</th>
                                    <th className='base-text'>Trading online</th>
                                    <th className='base-text'>Trading asistido</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='base-text'>Apertura de cuenta</th>
                                        <td colSpan={2} className='text-center base-text'>Sin cargo</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Mantenimiento de cuenta</th>
                                        <td colSpan={2} className='text-center base-text'>Sin cargo</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Compra / Venta de acciones</th>
                                        <td className='text-center base-text'>0.6%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Compra / venta de otros títulos de deuda (publicos)</th>
                                        <td className='text-center base-text'>0.6%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Compra / venta de otros títulos de deuda (privados)</th>
                                        <td className='text-center base-text'>0.6%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Compra / venta de cedears</th>
                                        <td className='text-center base-text'>0.6%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Compra de letras en dólar mep y en dolares cable</th>
                                        <td className='text-center base-text'>0.1%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Venta de letras en dólar mep y en dolares cable</th>
                                        <td className='text-center base-text'>0.6%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Compra / venta de letras en pesos</th>
                                        <td className='text-center base-text'>0.1%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Compra / venta de Opciones (Acciones, Cedears, Ons, Futuros, Indices)</th>
                                        <td className='text-center base-text'>0.6%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Licitaciones de Títulos Publicos y Privados</th>
                                        <td className='text-center base-text'>0.6%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Licitación de Letras</th>
                                        <td className='text-center base-text'>0.1%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Operaciones por mandato exterior</th>
                                        <td className='text-center base-text'>0.6%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Negociación de cheques diferidos y FCE (anual)</th>
                                        <td className='text-center base-text'>1.5%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Operaciones de futuros</th>
                                        <td className='text-center base-text'>0.15%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Caución colocadora % con detalle de prorrata (cada 90 días) pesos</th>
                                        <td className='text-center base-text'>0.5%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Caución tomadora (% a prorratear en 90 días) pesos</th>
                                        <td className='text-center base-text'>1.5%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Caución colocadora y tomadora % con detalle de prorrata (cada 90 días) dólar</th>
                                        <td className='text-center base-text'>0.1%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    
                                    <tr>
                                        <th className='base-text'>&nbsp;</th>
                                        <td className='text-center base-text'>&nbsp;</td>
                                        <td className='text-center base-text'>&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <th className='base-text'>Renta Títulos Valores</th>
                                        <td className='text-center base-text'>Hasta 0.60%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>

                                    <tr>
                                        <th className='base-text'>Amortización Títulos Valores</th>
                                        <td className='text-center base-text'>Hasta 0.60%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Transferencia monetaria</th>
                                        <td className='text-center base-text'>Hasta 0.60%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Comisión sobre Valores al Cobro</th>
                                        <td className='text-center base-text'>Hasta 0.60%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Comisión por Cheques Rechazados</th>
                                        <td className='text-center base-text'>Hasta 0.60%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Transferencias locales de Títulos Valores</th>
                                        <td className='text-center base-text'>Hasta 0.60%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Transferencias internacionales de Títulos Valores</th>
                                        <td className='text-center base-text'>Hasta 0.60%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Gastos por Conversión de Títulos Valores</th>
                                        <td className='text-center base-text'>Hasta 0.60%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>

                                    <tr>
                                        <th className='base-text'>Bonificación por trading intradiario 50%</th>
                                        <td className='text-center base-text'>Hasta 0.60%</td>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="fullwidth comsiones-footer-text">
            <Container>
                <Row className="g-0 d-flex justify-content-start" >
                    <Col xs={12} className="pb-0 pt-sm-5">
                        <p className='base-text'>Balanz se reserva el derecho de modificar en cualquier momento las comisiones y/o aranceles previstos en el presente, lo cual será informado a los inversores por este mismo medio.</p>
                        <p className='base-text'><b>Negociación secundaria:</b> Adicionalmente a los aranceles expuestos se le deberán sumar los derechos de Mercado y tasas de registro aplicables a las operaciones que fijen Bolsas y Mercados Argentinos S.A. (BYMA), Matba Rofex S.A., Mercado Abierto Electrónico S.A. (MAE) y Mercado Argentino de Valores SA (MAV), vigentes al momento de la operación de acuerdo a lo publicado: en www.byma.com.ar, www.matbarofex.com.ar, www.mae.com.ar y www.mav-sa.- com.ar. Esto no aplica a la negociación en las ruedas de segmento bilateral las cuales no establecen derechos de mercado.</p>
                        <p className='base-text'><b>Acreencias:</b> Se deberán sumar los aranceles que fije Caja de Valores S.A. de acuerdo a lo publicado en <a href="https://www.cajadevalores.com.ar" target="_blank">www.cajadevalores.com.ar</a>.</p>
                        <p className='base-text'>El IVA será aplicado según corresponda, sobre el importe de la comisión ó arancel.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
);

return (
    <main>
        <Layout title={pageTitle} description={pageDesc} childrem={page} category={"legales"}></Layout>
    </main>
);
};

// Step 3: Export your component
export default ComisionesPage;
