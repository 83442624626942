import React, { useEffect, useRef } from 'react';
import './ScrollButton.css';
export const ScrollButton = () => {
    const buttonRef = useRef(null);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 500) { 
          buttonRef.current.style.display = 'block';
        } else {
          buttonRef.current.style.display = 'none';
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' 
      });
    };
  
    return (
      <button 
        ref={buttonRef} 
        className="scroll-to-top-button" 
        onClick={scrollToTop} 
        style={{ display: 'none' }} 
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon">
          <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
        </svg>
      </button>
    );
}
