import React, {useEffect, useState} from "react";
import {Container, Row, Col, Card, CardGroup} from "react-bootstrap";
import { InvestmentNews } from "../investment_news/investmentNews";
import { Cursos_cards } from "../cursos_cards/cursos_cards";
import Button from '../buttons/button'
import SlickVideoSlider from "../slick_video_slider/skick_video_slider";
import { SalWrapper } from "react-sal";
import { Link } from 'gatsby';
import {FaChevronRight} from "react-icons/fa/index";
import EnvelopeIcon from "../../imgs/envelope-icon.svg"
import { apiRequest } from "../../apiRquest/apiRequest";
import './BalanzUniversity.css'
import './card_generic.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imagenCard1 from '../../imgs/web-1-curso-balanz.jpg'
import imagenCard2 from '../../imgs/web-2-curso-balanz.jpg'
import imagenCard3 from '../../imgs/web-3-curso-balanz.jpg'
import imagenCard4 from '../../imgs/web-4-curso-balanz.jpg'
import imagenCard5 from '../../imgs/web-5-curso-balanz.jpg'
import imagenCard6 from '../../imgs/web-6-curso-balanz.jpg'
import imagenCard7 from '../../imgs/web-7-curso-balanz.jpg'
import imagenCard8 from '../../imgs/web-8-curso-balanz.jpg'
import imagenCard9 from '../../imgs/web-9-curso-balanz.png'
import imagenCard10 from '../../imgs/web-10-curso-balanz.jpg'
import imagenCard11 from '../../imgs/web-11-curso-balanz.jpg'
import imagenCard12 from '../../imgs/web-12-curso-balanz.jpeg'


const BalanzUniversity = ({ artdata }) => {
    const [trainings, setTrainings] = useState([]);

    const slickSettings = {
        centerMode: true,
        centerPadding: "0px",
        initialSlide: 0,
        slidesToShow: 4,
        dots: true,
        arrows: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 992,
                settings: { centerMode: false, slidesToShow: 3, slidesToScroll: 3, infinite: false }
            },
            {
                breakpoint: 576,
                settings: {  centerMode: false, slidesToShow: 2, slidesToScroll: 2, infinite: false }
            }
        ]
    };
    const slickCursosSettings = {
        centerMode: false,
        centerPadding: "0px",
        initialSlide: 0,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 2000,
                settings: { centerMode: false, slidesToShow: 3, slidesToScroll: 3, infinite: false }
            },
            {
                breakpoint: 992,
                settings: { centerMode: false, slidesToShow: 2, slidesToScroll: 3, infinite: false }
            },
            {
                breakpoint: 576,
                settings: {  centerMode: false, slidesToShow: 1, slidesToScroll: 1, infinite: false }
            }
        ]
    };

    let nuestrosCursos = [
        {
                image: imagenCard1,
                title: "RENTA FIJA",
                description: "Conocerás en profundidad las inversiones en renta fija, obtendrás herramientas para valuar este tipo de inversiones, y aprenderás a interpretar los indicadores relevantes para negociarlos en el mercado.",
                url: "https://forms.office.com/r/TJhGx3Y3e8"
        },
        {
                image: imagenCard2,
                title: "FINANZAS PERSONALES",
                description: "Adquirirás las mejores herramientas para gestionar tus finanzas personales y encontrar oportunidades de ahorro, y alternativas de financiamiento e inversión.",
                url: "https://forms.office.com/r/igGZRdSvXt"
        },
        {
                image: imagenCard3,
                title: "INTRODUCCIÓN AL MERCADO DE CAPITALES",
                description: "Aprenderás todo sobre el funcionamiento del mercado de capitales y las posibilidades que nos brinda. Obtendrás las herramientas necesarias para iniciarte en el mundo de las inversiones.",
                url: "https://forms.office.com/Pages/ResponsePage.aspx?id=rusjEQagx0Keo8hKL-sOZWSTdnh1tjBOjyqqU6trKTlUN04xOTQ5UFhVQjBJQVJVVUdRWkxJOEUzUS4u"
        },
        {
                image: imagenCard4,
                title: "INTRODUCCIÓN A LAS FINANZAS PYME",
                description: "Aprenderás junto a los mejores especialistas de Balanz las alternativas de financiamiento de corto, mediano y largo plazo que pueden utilizar las PYME, y cuáles son las mejores opciones para el manejo de tesorerías.",
                url: "https://forms.office.com/r/3jif2bqLvV"
        },
        {
                image: imagenCard5,
                title: "ANÁLISIS FUNDAMENTAL PARA INVERTIR EN ACCIONES",
                description: "Analizaremos las inversiones en acciones, para tomar decisiones de inversión, teniendo en cuenta la valuación de las empresas y el contexto que las afecta.",
                url: "https://forms.office.com/r/VU3Z5kakUj"
        },
        {
                image:  imagenCard6,
                title: "INTRODUCCIÓN A LA RENTA VARIABLE: CEDEAR",
                description: "Aprenderás junto a los mejores especialistas de Balanz sobre los CEDEAR y cómo este instrumento que cotiza en pesos te permite dolarizar tu cartera de inversión.",
                url: "https://forms.office.com/r/ZGdJgsSy7k"
        },
        {
                image: imagenCard7,
                title: "ANÁLISIS TÉCNICO",
                description: "Obtendrás las herramientas fundamentales para optimizar tus decisiones de inversión utilizando Anáisis técnico. Vas a aprender cómo interpretar gráficos, reconocer tendencias y figuras, y cómo aplicarlo en tus inversiones.",
                url: "https://forms.office.com/r/XqR2Bz6ynv"
        },
        {
                image:  imagenCard8,
                title: "FONDOS COMUNES DE INVERSIÓN",
                description: "Conocerás todo acerca de los Fondos comunes de inversión y cómo elegir el que mejor se adapta a tu perfil y objetivos. Además, aprenderás sobre los ETF y la forma de invertir en ellos en el mercado local.",
                url: "https://forms.office.com/r/bFmCQqStCj"
        },
        {
                image: imagenCard9,
                title: "INVERSIONES DE IMPACTO: FINANZAS SUSTENTABLES Y BONOS VERDES",
                description: "Conocerás el estado actual de las inversiones socialmente responsables en el mercado de capitales, su relevancia y las alternativas disponibles para realizar inversiones sustentables.",
                url: "https://forms.office.com/r/C6ntF3RQtB"
        },
        {
                image: imagenCard10,
                title: "CURSO PREPARATORIO DE IDÓNEOS",
                description: "Aprenderás  todo lo necesario para rendir el examen de idoneidad ante la CNV, pudiendo así convertirse en agentes del mercado de capitales.",
                url: "https://forms.office.com/r/TkxX5ZmbEr"
        },
        {
                image: imagenCard11,
                title: "TALLER DE RENTA FIJA: INVERTIR EN ON",
                description: "Conocerás las principales características de las Obligaciones Negociables, así como las herramientas para su valuación y negociación en el Mercado de Capitales.",
                url: "https://forms.office.com/r/94wwTvfmAg"
        },
        {
                image: imagenCard12,
                title: "APRENDÉ A INVERTIR Y GESTIONAR TUS FINANZAS PERSONALES",
                description: "Este plan de formación inicial incluye tres bloques introductorios que te brindarán herramientas prácticas para organizar tus finanzas personales y comenzar a invertir: Finanzas Personales, FCI e Introducción a la Renta Variable.",
                url: "https://forms.office.com/pages/responsepage.aspx?id=rusjEQagx0Keo8hKL-sOZZVV0_mNDw9PiK0z61oMUhdUNldGOEYxVVA0T0ExODlLU09HMzM1WTJSVC4u&route=shorturl"
        },
    ];

    let cursos = [
        {
            neBanner: "/imgs/art-capitales2.jpg",
            neTitle: "Introducción al Mercado de Capitales",
            neLink: "https://youtu.be/ztVF8qRT8Gc ",
            neText: "En este curso aprenderás acerca del valor tiempo del dinero, el costo de oportunidad, los instrumentos y participantes del mercado, entre muchos otros temas."
        },
        {
            neBanner: "/imgs/art_finanzas.jpg",
            neTitle: "Finanzas Personales",
            neLink: "https://youtu.be/ztVF8qRT8Gc ",
            neText: "Aprendé todo sobre cómo administrar tus finanzas. Tenés la oportunidad de adquirir todos los instrumentos para hacer rendir más tus ahorros."
        }
    ];

    const [articulosquery, setArticulosquery] = useState(artdata.filter(a => a.node.highlighted === 1));
    let tempArts = [...artdata]
    // console.log('tempArts', tempArts)
    tempArts.sort((a, b) => { return a.node.order - b.node.order } );
    let articulos2query = articulosquery.slice(1,4)
    let articulos2querydata2 = [];
    articulos2query.forEach((element) => {
        articulos2querydata2.push(element.node);
    });
    const [articulos2querydata, setArticulos2querydata] = useState(articulos2querydata2);

    useEffect(() => {

        apiRequest
            .get("/v1/university/highlighted")
            .then((res) => {
                const university = [];
                res.data.forEach(u => {
                    university.push({node: u})
                })
                let articles = res.data.slice(1,4)
                setArticulos2querydata(articles);
                setArticulosquery(university)
                
            })
            .catch((err) => {
                console.log(err);
            })


        apiRequest
            .get("/v1/trainings")
            .then((res) => {
                const data = [];
                res.data.data.forEach((element) => {
                    // console.log(element)
                    data.push({
                        url: element.link,
                        title: element.title
                    })
                })
                setTrainings(data);
            })
            .catch((err) => {
                console.log(err);
            })
        
    }, [setTrainings, setArticulosquery, setArticulos2querydata])

    
    return <SalWrapper>
        <section className="fullwidth page-description"
                data-sal="fade"
                data-sal-delay="0"
                data-sal-duration="200"
        >
            <div className="bg-lt-grey ">
            <Container>
                <Row className="">
                    <Col xs={12} >
                        <div className="py-md-5 py-3">
                            <p className="base-text">
                                <strong>En Balanz estamos comprometidos a promover la educación financiera en distintos ámbitos para facilitar el acceso al Mercado de Capitales.</strong>
                            </p>
                            <p className="base-text">
                                Creemos que las oportunidades se detectan mejor con información y conocimiento.
                            </p>
                            <p className="base-text">
                                Por eso, nuestro objetivo es impulsar la educación financiera y contribuir con la difusión del conocimiento para un mercado más formado y transparente, al alcance de todos.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
        </section>
        <section className="fullwidthbg balanz-cursos part-blue py-2 balanz-university-cursos"
                     data-sal="fade"
                     data-sal-delay="0"
                     data-sal-duration="200"
            >
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}  className="column col-text text-center">
                            <div className="section-content mt-2">
                            <h2
                                className="texto-curso mx-auto"
                                data-sal="slide-up"
                                data-sal-delay="100"
                                data-sal-duration="200" 
                                >
                                    Capacitarte siempre es la mejor inversión 
                                </h2>
                                <span className="texto2 mx-auto"
                                   data-sal="slide-up"
                                   data-sal-delay="100"
                                   data-sal-duration="200"
                                >Aprendé todo lo que tenés que saber para invertir mejor
                                <br/>
                                y potenciar tus ahorros con nuestra formación.
                                </span>
                                <div className="cta py-3">
                                <Button variant="secondary" text="Ver más" onClick={() => { window.open("https://balanzuniversity.com/", "_blank") }} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        <section className="fullwidth programas"
                data-sal="fade"
                data-sal-delay="0"
                data-sal-duration="200"
        >
            <Container>
                <Row className="">
                    <h2>Programas a medida y extensión educativa</h2>
                    <Col xs={12} >
                        <div className="">
                        <p className="base-text">
                            Brindamos charlas, capacitaciones y cursos a empresas, colegios, universidades y organizaciones educativas, donde acercamos múltiples herramientas, ajustándolas a la necesidad de cada público. Desarrollamos propuestas de capacitación flexible de acuerdo con los contenidos, modalidades (presencial y/o virtual) y tiempos deseados.                                </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="fullwidth cursos-destacados d-none">
            <Container>
                    <Cursos_cards
                        sectionTitle="Cursos destacados"
                        items = {cursos}
                    />
            </Container>
        </section>
        <section className="fullwidth top-news">
            <Container>
            <Row className="justify-content-center g-3 g-md-3">
            <h2>Conocé nuestra formación </h2>
                <Slider {...slickCursosSettings}>
                    {nuestrosCursos.map((curso, key) => {
                        return (
                            <CardGroup key={key} className="Cursos-Container">
                                <Card className="Cursos-Card">
                                   <div className="newsBanner"
                                   style={{
                                        backgroundImage: `url(${curso.image})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "right"
                                   }} 
                                   />
                                    <span className="Cursos-Topic" >
                                        {curso.title}
                                    </span>
                                    <div className="card-body newsContent" >
                                    <div className="card-text newsText"> 
                                        {curso.description}
                                     </div>
                                    </div>
                                    <div className="card-footer">
                                        <Button
                                        variant="secondary newsButton"
                                        text="Informar próximo inicio"
                                        onClick={() => { window.open(`${curso.url}`, "_blank") }}
                                        />
                                    </div>
                                </Card>
                            </CardGroup>
                        );
                    })}
                </Slider>  

             </Row>
            </Container>
        </section>
        <section className="fullwidth articulos">
            <Container>
                <Row className="justify-content-center g-3 g-md-3">
                    <Col xs={12} md={8} className="order-1"><h2>Lee nuestros artículos</h2></Col>
                    {articulosquery.length > 0 &&
                        (<Col xs={12} md={6} className="d-flex flex-fill order-3">
                            <Card className="artCard flex-fill">
                                <div
                                    className="artBanner"
                                    style={{
                                        backgroundImage: `url(${articulosquery[0].node.imgUrl})`,
                                        backgroundSize: "cover",
                                    }}
                                >
                                    <h3 className="card-title artTitle">
                                        <div dangerouslySetInnerHTML={{__html: articulosquery[0].node.title}}/>
                                    </h3>
                                </div>
                                <div className="card-body artContent">
                                    <div className="card-text artText">
                                        <div dangerouslySetInnerHTML={{__html: articulosquery[0].node.htmldesc}}/>

                                    </div>
                                    <div className="pt-3 pt-md-4 pt-lg-5">
                                        <Link to={`/balanz-university/${articulosquery[0].node.slug}`}>
                                            <Button variant="secondary artButton" text="Ver más"/>
                                        </Link>
                                    </div>

                                </div>
                            </Card>
                        </Col>)
                    }
                    <Col xs={12} md={6} className="order-4">
                        <InvestmentNews
                            containerClass="university-articles"
                            investmentNews={articulos2querydata}
                            basePath="balanz-university"
                        />
                    </Col>
                    <Col xs={12} md={4} className="d-flex align-items-start flex-row justify-content-end order-5 order-md-2 ">
                        <Link to="/balanz-university/articulos">
                            <div className="highlightbold base-text text-end secondary-color m4 flex-grow-1">
                                Ver todos <FaChevronRight />{" "}
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="fullwidth latest-trainings"

                data-sal="slide-up"
                data-sal-delay="0"
                data-sal-duration="100"
        >
            <Container>
                <Row className="justify-content-center g-3 g-md-3">
                    <h3 className="ash2">Mirá las últimas capacitaciones</h3>
                    <SlickVideoSlider linkList={trainings} slickSettings={slickSettings} />
                    <div className="text-center py-3 py-sm-4 m-0"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="200">
                        <Button variant="secondary" text="Accedé a nuestro canal de YouTube" onClick={() => { window.open("https://www.youtube.com/channel/UCl7iljPb3wACm-lOyqM1dHA", "_blank") }} />
                    </div>
                </Row>
            </Container>
        </section>
        <section className="fullwidth escribinos">
            <div className="bg-lt-grey py-3">
                <Container>
                    <Row>
                        <Col className="d-flex align-items-center">
                            <div className="envelope-holder">
                                <img src={EnvelopeIcon} alt="email" className="envelope" />
                            </div>
                            <p>
                                Escribinos para saber más de nuestros programas a <a className="secondary-color" href="mailto: university@balanz.com.">university@balanz.com</a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    </SalWrapper>
}

export default BalanzUniversity;